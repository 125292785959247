<template>
    <div class="d-flex align-items-center justify-content-between border-bottom font-weight-bold">
        <div class="w-50 d-flex align-items-center p-3">
            <div class="w-50">{{stringLimit(deviceObject.getName(), 14)}}</div>
            <div class="w-50 d-flex justify-content-end align-items-center">
                <div class="mr-3">
                    <Icon icon-name="clock"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="lastUpstreamDateAgo"
                          :fill="iconColor"
                          width="24"
                          height="24"/>
                </div>
                <div class="cursor-pointer mr-3" @click="$router.push({name: 'Device', params: { id: deviceObject.getId() }})">
                    <Icon data-toggle="tooltip"
                          data-placement="top"
                          :data-original-title="$t('Статистика')"
                          icon-name="statistic"
                          width="24"
                          height="24"
                          :fill="iconColor"

                    />
                </div>
                <DeviceCardAccidentsIndicator
                    class="mr-3"
                    :deviceObject="deviceObject"
                    :timeseries="timeseries"
                    :accidents="accidents"
                    @onHasAccidents="val => this.hasAccidents = val"/>
                <DeviceCardHeaderBattery v-if="deviceBatVolt"
                                         class="mr-3"
                                         :deviceObject="deviceObject"
                                         :timeseries="timeseries"/>
                <div class="p-0 m-0 cursor-pointer"
                     data-toggle="tooltip"
                     data-placement="top"
                     :title="$t('Настройки')">
                    <Icon icon-name="setting"
                          data-toggle="dropdown"
                          :fill="iconColor"
                          width="24"
                          height="24"/>
                    <div class="dropdown-menu app-dropdown font-weight-500">
                        <a v-if="isCurrentUserDevice" class="dropdown-item d-flex align-items-center" href="#" @click.prevent="$emit('deviceDoUpdate', deviceObject)">
                            <Icon icon-name="edit"
                                  fill="#390E4E"
                                  width="20"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Редактировать') }}</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="$emit('deviceDoShowGeoPosition', deviceObject)">
                            <Icon icon-name="location"
                                  width="20"
                                  fill="#00800D"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Местоположение') }}</span>
                        </a>
                        <a v-if="isCurrentUserDevice" class="dropdown-item d-flex align-items-center" href="#"
                           @click.prevent="$router.push({name: 'DeviceAlarm', params: { id: deviceObject.getId() }})">
                            <Icon icon-name="info-circle"
                                  width="20"
                                  fill="#FF7B01"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Настройка аварий') }}</span>
                        </a>
                        <a v-if="isCurrentUserDevice" class="dropdown-item d-flex align-items-center" href="#"
                           @click.prevent="$router.push({name: 'Accidents', params: {deviceId: deviceObject.getId() }})">
                            <Icon icon-name="danger"
                                  width="20"
                                  fill="#FF2F2F"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Обзор инцидентов') }}</span>
                        </a>
                        <a v-if="isCurrentUserDevice" class="dropdown-item d-flex align-items-center" href="#"
                           @click.prevent="$router.push({name: 'DeviceAccess', params: { deviceId: deviceObject.getId() }})">
                            <Icon icon-name="shield"
                                  width="20"
                                  fill="#00409F"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Управление доступом') }}</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="switchAutoUpdate">
                            <Icon icon-name="refresh"
                                  width="20"
                                  :fill="isEnabledAutoUpdate ? '#000000' : '#00800D'"
                                  height="20"/>
                            <span class="pl-2">{{ isEnabledAutoUpdate ? $t('Отключить авто-обновление') : $t('Включить авто-обновление') }}</span>
                        </a>
                        <div v-if="isCurrentUserDevice" class="dropdown-divider"></div>
                        <a v-if="isCurrentUserDevice" class="dropdown-item d-flex align-items-center" href="#"
                           @click.prevent="$emit('deviceDoRemove', deviceObject)">
                            <Icon icon-name="delete"
                                  width="20"
                                  fill="#FF0000"
                                  height="20"/>
                            <span class="pl-2">{{ $t('Удалить устройство') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center w-17 border-left py-3">{{ $t('Дата') }}</div>
        <div class="text-center w-33 border-left py-3">{{ $t('Статистика') }}</div>
    </div>
</template>

<script>
import DefaultCardHeader from "../default/DefaultCardHeader";

export default {
    name: "GerkonCardHeader",
    extends: DefaultCardHeader,
}
</script>
