<template>
    <div>
        <div class="deviceCardMetricItem d-flex align-items-center justify-content-between w-100 font-weight-500"
             style="height: 57px">
            <div class="w-50 p-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="pr-2">
                        <div class="pr-1">
                    <span v-if="statusMetric.getPortIndex() !== null" class="metric-icon-index">
                        {{ statusMetric.getPortIndex() + 1 }}
                    </span>
                            <Icon :icon-name="statusMetric.getIcon()"
                                  width="23"
                                  height="23"
                                  :fill="statusMetric.getColor()"/>
                        </div>
                    </div>
                    <div>
                        {{ statusMetric.getName() }}
                    </div>
                </div>
            </div>
            <div class="w-17 px-3 pt-1 border-left h-100 d-flex align-items-center justify-content-center text-center"
                 :class="{'bg-accident': metricHasAccident(statusMetric)}">
                    {{ statusDate }} <br>
                    {{ statusTime }}
            </div>
            <div class="w-33 px-3 pt-1 border-left h-100 d-flex align-items-center justify-content-center">
                <div v-if="getMetricTimeseries(statusMetric).length">
                    <div v-if="classStatus0 && classStatus1" class="d-inline-flex justify-content-center align-items-center mr-2">
                        <i v-if="status" class="fas" :class="classStatus1"></i>
                        <i v-else class="fas" :class="classStatus0"></i>
                    </div>
                    <span v-if="status">{{ textStatus1 }}</span>
                    <span v-else>{{ textStatus0 }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-center h-100" v-else>N/A</div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultCardMetricList from "../default/DefaultCardMetricList";
import moment from "moment-mini";

export default {
    name: "GerkonCardMetricList",
    extends: DefaultCardMetricList,
    data() {
        return {
            textStatus1: this.$t('Открыто'),
            textStatus0: this.$t('Закрыто'),
            classStatus1: "fa-unlock text-danger",
            classStatus0: "fa-lock text-success",
        }
    },
    computed: {
        status() {
            const value = parseInt(this.getMetricLabel(this.statusMetric, 0))
            if (value === 1) {
                return true;
            }
            if (value === 0) {
                return false;
            }
            return null;
        },
        statusMetric() {
            return this.deviceMetrics.find(metric => metric.getSlug() === 'status');
        },
        statusDate() {
            return moment(this.timeseries.find(item => item.getSlug() === "status")?.getLatestData().getDate()).format("HH:mm:ss");
        },
        statusTime() {
            return moment(this.timeseries.find(item => item.getSlug() === "status")?.getLatestData().getDate()).format("YYYY-MM-DD");
        },
    }
}
</script>
