<script>
import GerkonCardMetricList from "./GerconCardMetricList";
import DefaultCard from "../default/DefaultCard";
import GerkonCardHeader from "./GerkonCardHeader";

export default {
    name: "GerkonCard",
    extends: DefaultCard,
    components: {
        HeaderComponent: GerkonCardHeader,
        MetricList: GerkonCardMetricList,
    }
}
</script>
